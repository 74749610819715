import React from 'react';
import Layout from '../components/layout';
import { Link } from 'gatsby';
import DemordauntFam from '../images/demordaunt_fam.png';
import Seo from '../components/seo';

const BiographyPage = () => {
  return (
    <Layout>
      <Seo title="Meet Gayann" />
      <div className="py-16 bg-blue-900 overflow-hidden bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
          <div className="text-base max-w-prose mx-auto lg:max-w-none">
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-red-600 sm:text-4xl">
              Meet Gayann
            </p>
          </div>
          <div className="sm:hidden">
            <img
              className="rounded-lg shadow"
              src={DemordauntFam}
              alt="Gayann Demordaunt's Family"
            />
          </div>
          <div className="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
            <p className="text-lg text-gray-500">
              Gayann is running on a platform of lower taxes, limited government, and parental empowerment in education.
            </p>
          </div>
          <div className="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
            <div className="relative z-10">
              <div className="prose prose-indigo text-gray-500 mx-auto lg:max-w-none text-lg">
                <p>
                  Gayann has a long history of protecting Idaho families. She believes that local control of education is best for students. She believes that the greatest form of local control are the CHOICES made by parents. Because of this she has fought for legislation to empower parents. She has introduced important legislation to make curriculum transparent and reflect Idaho values. Gayann believes that we should make direct and deliberate investments in Idaho’s education with an expectation of student academic growth.
                </p>
                <br />
                <p>
                  You might be familiar with her leadership on education issues. Gayann was appointed to the Idaho Public Charter School Commission, where she has served for nearly 9 years. Because of Gayann’s work on the commission thousands of Idaho families now have education choices that best fits their children’s needs.
                </p>
                <br />
                <p>
                  Gayann believes that every Idahoan should be free from government intrusion. She believes that we do this by getting government out of the way. By keeping business regulation to a minimum and by reducing the tax burden, we allow businesses in Idaho to grow.
                </p>
                <br />
                <ul className="ml-4">
                  <li>Bachelor’s degree from Brigham Young University</li>
                  <li>Served on the Board of the Red Cross of Greater Idaho</li>
                  <li>Served on the Executive Board of the Ada County Republicans</li>
                  <li>Elected as Ada County Precinct Committeeman and District Chairman</li>
                  <li>Governor appointee to the Idaho Public Charter School Commission</li>
                  <li>Volunteer in civic groups, and church</li>
                  <li>Married for 35 years, mother of six</li>
                  <li>Has lived in District 14 for 20 years</li>
                </ul>
              </div>
              <div className="mt-10 flex text-base max-w-prose mx-auto lg:max-w-none space-x-8">
                <Link
                  to="/issues"
                  className="font-medium text-red-600 text-lg hover:text-red-300"
                >
                  Platform &rarr;
                </Link>
                <Link
                  to="/gallery"
                  className="font-medium text-red-600 text-lg hover:text-red-300"
                >
                  Gallery &rarr;
                </Link>
              </div>
            </div>
            <div className="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
              <svg
                className="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20"
                width={404}
                height={384}
                fill="none"
                viewBox="0 0 404 384"
                aria-hidden="true"
              >
                <defs>
                  <pattern
                    id="bedc54bc-7371-44a2-a2bc-dc68d819ae60"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-blue-100" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={384} fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
              </svg>
              <blockquote className="hidden sm:block relative bg-white rounded-lg shadow-lg">
                <img
                  className="rounded-lg shadow"
                  src={DemordauntFam}
                  alt="Gayann DeMordaunts Family"
                />
              </blockquote>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default BiographyPage;